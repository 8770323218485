/**
 * @module OK/PackageRegistry
 */

interface MessageResource {
    pkg: string;
    messages: object;
    parsedResources: object;
    has(key: string): boolean;
    getLMsg(key: string, args?: object): string;
    subscribe(callback: () => void): void;
}

const registry: Record<string, Promise<MessageResource>> = {};
const resolveRegistry: Record<string, (resource: MessageResource) => void> = {};

export function hasPackagePromise(pkg: string): boolean {
    return registry.hasOwnProperty(pkg);
}

export function getPackagePromise(pkg: string): Promise<MessageResource> {
    return registry[pkg];
}

export function addPackagePromise(pkg: string): void {
    registry[pkg] = new Promise(res =>
        resolveRegistry[pkg] = res
    );
}

export function getPackageResolve(key: string): (resource: MessageResource) => void {
    return resolveRegistry[key];
}

export function deallocatePackage(pkg: string): void {
    delete registry[pkg];
    delete resolveRegistry[pkg];
}
